import {
  setPropertyData,
  setPlaceData,
  setRoomData,
  setDiscData,
  setRulesData,
  setBookingPrefrenceData,
  setConfirmAddressData,
  setAmenitiesData,
} from "./redux/listingTypeSlice";
import {
  updateAdditionalGuests,
  updateAdditionalBeds,
  updatePerNightCost,
  updateBaseGuest,
  updateWeekendPrice,
} from "./redux/PricingCounter";

import { setFailedImages, setSelectedImages } from "./redux/dropboxSLicer";

import {
updateGuests,
updateBedrooms,
updateBeds,
updateBathrooms,
} from "./redux/CounterSlice";
import {
  setAddonId,
  setPreferenceId,
  setPropId,
  setPropName,
  setAddonPropId,
} from "./redux/idSlice";

import { useDispatch } from "react-redux";
import { amenitiesFilter, bathroomsFilter, bedroomsFilter, bedsFilter, FilterGet, maxFilter, minFilter, propertyFilter } from "./redux/FilterSlicer";
import moment from 'moment';
import { fullNameAction, getImage, getUid, tokenAction, usernameAction } from "./redux/HostDetailSlice";
import axios from "axios";
import { setEditFailedImages, setEditSelectedImages } from "./redux/EditDropBoxSlicer";
import { setPromptOkay } from "./redux/GetPromptSlice";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

//localport urls
// export const apiMainUser = "http://192.168.0.242:5001/user/";
// export const apiPublicUser = "http://192.168.0.242:5001/";

//ipaddress urls
// export const apiMainUser = "http://65.0.167.231:5001/user/";
//  export const apiPublicUser = "http://65.0.167.231:5001/";
//  export const apiAdminUser = "http://65.0.167.231:5001/admin/";


//we work paji pc---
// export const apiMainUser = "http://10.15.22.77:5001/user/";
//  export const apiPublicUser = "http://10.15.22.77:5001/";



//staging urlspm

export const apiMainUser = "https://api.jumboholidayz.com/user/";
export const apiPublicUser = "https://api.jumboholidayz.com/";
export const rzpkey = "rzp_test_rBs4LF0IHGdHKf";


// //live urls
// export const apiMainUser = "https://api-live.jumboholidayz.com/user/";
// export const apiPublicUser = "https://api-live.jumboholidayz.com/";
// export const rzpkey = "rzp_live_OftYkqR87yujMV";

// export const apiAdminUser = "https://api-live.jumboholidayz.com/admin/";



// export default apiMainAdmin;

export const checkErrors = (errors) => {
  console.log(errors, "errors");

  if (Object.keys(errors).length !== 0) {
    return true;
  }
  return false;
};




export const getMetadataValue = (value) => {
  if (value) return value;
  return null;
};

export const isUrl = (url) => {
  const pattern = /^((http|https):\/\/)(www\.)?[a-zA-Z0-9@:%.\+?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%.\+?&//=]*)$/;

  // If the URL is empty, return false
  if (url.length === 0) {
    return false;
  }

  // Return true if the URL matches the regex pattern
  if (pattern.test(url)) {
    return true;
  } else {
    return false;
  }
}

const UsefetchUrlFunc = (uri, newObj)=>{
  
    // Parse the current URI
    const url = new URL(uri);
    
    // Convert the existing query parameters to an object
    const queryParams = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
  
    // Compare and update values in the object
    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        const newValue = newObj[key];
        if (newValue !== undefined && newValue !== null && newValue !== queryParams[key]) {
          queryParams[key] = newValue;
        }
      }
    }
    console.log(queryParams,newObj)
    // Convert the object back to a query string
    const updatedQueryParams = new URLSearchParams(queryParams).toString();
  
    // Update the URI with the new query string
    url.search = updatedQueryParams;
    
    return url.toString();
  }

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  






  const UseCleanCreateListingReduxNew = () => {
    const dispatch = useDispatch();
  
    const cleanCreateListingReduxNew = () => {
  
  
        dispatch(setPropId(null));
        dispatch(setPropName(null));
        dispatch(setAddonId(null));
  
        dispatch(setPreferenceId(null));
        dispatch(setAddonPropId(null));
        dispatch(setPlaceData(null));
        dispatch(setPropertyData(null));
        dispatch(setRoomData(null));
        dispatch(setAmenitiesData(null));
        dispatch(setDiscData(null));
        dispatch(setRulesData(null));
        dispatch(setBookingPrefrenceData(null));
        dispatch(setConfirmAddressData(null));
        dispatch(updateAdditionalGuests(null));
        dispatch(updateAdditionalBeds(null));
        dispatch(updatePerNightCost(null));
        dispatch(updateBaseGuest(null));
        dispatch(setSelectedImages([]));
        dispatch(setEditSelectedImages([]));
        dispatch(setEditFailedImages([]));
        dispatch(setFailedImages([]))
        dispatch(updateGuests(1));
        dispatch(updateBedrooms(1));
        dispatch(updateBeds(1));
        dispatch(updateBathrooms(1));
        dispatch((FilterGet(null)));
        dispatch((bedsFilter(null)));
       
        dispatch((bathroomsFilter(null)));
        dispatch((bedroomsFilter(null)));
  
        dispatch((amenitiesFilter(null)));
        dispatch((minFilter(null)));
        dispatch((maxFilter(null)));
        dispatch((propertyFilter(null)))
    };
  
    return cleanCreateListingReduxNew;
  };

const UseCleanCreateListingRedux = () => {
  const dispatch = useDispatch();

  const cleanCreateListingRedux = () => {

    dispatch(fullNameAction(null))
    dispatch(usernameAction(null))
    dispatch(tokenAction(null))

    dispatch(getImage(null))
    dispatch(getUid(null))
    dispatch(setPropId(null));
    dispatch(setPropName(null));
    dispatch(setAddonId(null));

    dispatch(setPreferenceId(null));
    dispatch(setAddonPropId(null));
    dispatch(setPlaceData(null));
    dispatch(setPropertyData(null));
    dispatch(setRoomData(null));
    dispatch(setAmenitiesData(null));
    dispatch(setDiscData(null));
    dispatch(setRulesData(null));
    dispatch(setBookingPrefrenceData(null));
    dispatch(setConfirmAddressData(null));
    dispatch(updateAdditionalGuests(null));
    dispatch(updateAdditionalBeds(null));
    dispatch(updatePerNightCost(null));
    dispatch(updateWeekendPrice(null));

    dispatch(updateBaseGuest(null));
    dispatch(setSelectedImages([]));
    dispatch(setFailedImages([]))
    dispatch(setEditSelectedImages([]));
    dispatch(setEditFailedImages([]));
    dispatch(updateGuests(1));
    dispatch(updateBedrooms(1));
    dispatch(updateBeds(1));
    dispatch(updateBathrooms(1));
    dispatch((FilterGet(null)));
    dispatch((bedsFilter(null)));
   
    dispatch((bathroomsFilter(null)));
    dispatch((bedroomsFilter(null)));

    dispatch((amenitiesFilter(null)));
    dispatch((minFilter(null)));
    dispatch((maxFilter(null)));
    dispatch((propertyFilter(null)))

  };

  return cleanCreateListingRedux;
};

//Fethicng api-----------------------------------------------------------------
const fetchDataFromApi = async (apiEndpoint) => {
  try {
    const response = await fetch(apiEndpoint, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error("Failed to fetch :", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching :", error);
    return null;
  }
};


//------------------------------------------------------------------------


const updateURLWithDates = (selectedCheckIn, selectedCheckOut ) => {

  console.log(selectedCheckIn,selectedCheckOut,"datesshow")
  const currentUrl = window.location.href;
  const queryParams = {
    checkin: selectedCheckIn?.format("YYYY-MM-DD"),
    checkout: selectedCheckOut?.format("YYYY-MM-DD"),
  };
  const updatedUrl = fetchUrlFunc(currentUrl, queryParams);
  window.history.replaceState(null, null, updatedUrl);
};

const updateURLWithCounts = (adultCount,childrenCount,infantCount) => {
  const currentUrl = window.location.href;
  const queryParams = {
    adults: adultCount,
    children: childrenCount,
    infants: infantCount,
  };
  const updatedUrl = fetchUrlFunc(currentUrl, queryParams);
  window.history.replaceState(null, null, updatedUrl);
};

const calculateBookingPrice = (propertyId, guestCount, selectedCheckIn, selectedCheckOut)=> {
  const pricecalldata = {
    property_id: propertyId,
    guest_count: parseInt(guestCount) || 1,
    checkin_date: selectedCheckIn,
    checkout_date: selectedCheckOut,
  };

  return axios
    .post(apiPublicUser + "calculate_booking_price", pricecalldata)
    .then((response) => {
      let alterResponse = [];
      response.data.data.forEach((item) => {
        const key = Object.keys(item)[0];
        alterResponse[key] = item[key];
      });
      // setSuccessDataPrice(alterResponse);
      return alterResponse; // You can return the data if needed
    })
    .catch((error) => {
      console.error("Error while calculating booking price:", error);
      throw error; // Re-throw the error for the caller to handle if needed
    });
}




const getBlockedDates = async (propid) => {
  try {
    const response = await axios.get(apiPublicUser + "get_slots_by_property_id/" + propid);
    var maxDate, minDate;
    var blockedDates = [];
    var blockedDatesCheckin = [];
    var blockedDatesCheckout = [];
    const allDates = response.data.data.map((days, i) => {

      // var prevDate
      // console.log(response.data.data[i-1], 'hellonew');
      // console.log(days, 'hellonew');
      let datebe = days.date.split("T");
      if (i === 0) {
        minDate = new Date(datebe[0]);
      }
      if (days.block === 1 || days.booked === 1) {
        blockedDates.push(new Date(datebe[0]));
      }
      if (days.block === 1 || (days.booked === 1 && days.ischeckin === 1)) {
        blockedDatesCheckin.push(new Date(datebe[0]));
      }
      if (((days.block === 1 || days.booked === 1) && days.ischeckout === 1) || (days.block === 1 && response.data.data[i - 1]?.block === 1)) {
        blockedDatesCheckout.push(new Date(datebe[0]));
      }
      if (i === response.data.length - 1) {
        maxDate = new Date(datebe[0]);
      }

      return new Date(datebe[0]);
    });
    
    var resData = {
      minDate: minDate,
      maxDate: maxDate,
      blockedDates: blockedDates,
      blockedDatesCheckin: blockedDatesCheckin,
      blockedDatesCheckout: blockedDatesCheckout,
      allDates: allDates,
      response: response,
    };

    return resData;
  } catch (error) {
    // Handle errors here
    console.error(error);
    throw error; // Rethrow the error to propagate it
  }
};

const getJustBlockedDates = async (propid) => {

  console.log('calledDates')
  try {
    const response = await axios.get(apiPublicUser + "get_slots_by_property_id/" + propid);
    var maxDate, minDate;
    var blockedDates = [];
    var blockedDatesCheckin = [];
    var blockedDatesCheckout = [];
    const allDates = response.data.data.map((days, i) => {

      // var prevDate
      // console.log(response.data.data[i-1], 'hellonew');
      // console.log(days, 'hellonew');
      let datebe = days.date.split("T");
      if (i === 0) {
        minDate = new Date(datebe[0]);
      }
      if (days.booked === 1) {
        blockedDates.push(new Date(datebe[0]));
      }
      if ((days.booked === 1 && days.ischeckin === 1)) {
        blockedDatesCheckin.push(new Date(datebe[0]));
      }
      if ((days.booked === 1 && days.ischeckout === 1) || (days.block === 1 && response.data.data[i - 1]?.block === 1)) {
        blockedDatesCheckout.push(new Date(datebe[0]));
      }
      if (i === response.data.length - 1) {
        maxDate = new Date(datebe[0]);
      }

      return new Date(datebe[0]);
    });
    
    var resData = {
      minDate: minDate,
      maxDate: maxDate,
      blockedDates: blockedDates,
      blockedDatesCheckin: blockedDatesCheckin,
      blockedDatesCheckout: blockedDatesCheckout,
      allDates: allDates,
      response: response,
    };

    return resData;
  } catch (error) {
    // Handle errors here
    console.error(error);
    throw error; // Rethrow the error to propagate it
  }
};

const formatIndianRupees = (number) =>{  
  if(number !== undefined){
  number = number.toString().split(".");
  return number[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
  }
  return 0;
}


  // utils.js

  export const capitalizeText = (text) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return '';
  };
  

export const formatIndianRupeesFunc = formatIndianRupees;
export const getBlockedDatesFunc = getBlockedDates;
export const getJustBlockedDatesFunc = getJustBlockedDates;

export const  calculateBookingPriceFunc = calculateBookingPrice;

export const CleanCreateListingRedux = UseCleanCreateListingRedux;
export const CleanCreateListingReduxNew = UseCleanCreateListingReduxNew;

export const fetchUrlFunc  = UsefetchUrlFunc ;

export const updateUrlWithDatesFunc = updateURLWithDates;
export const updateURLWithCountsFunc = updateURLWithCounts;

export const fetchDataFromApiFunc = fetchDataFromApi;

export const useQuerryFunc = useQuery;
// export const handleDeleteConfirmationFunc = useDeleteConfirmation;
